@media print {
  * {
      -webkit-print-color-adjust: exact;
  }

  body {
    background-image: none !important;
  }
}

@page {
  margin: 0;
}

.print-report {
  &-graph-container {
    width: 100%;
    max-width: 100vw;
  }
  &-cover {
    width: 100vw !important;
    height: 86vh;
    &-logo-header {
      position: absolute;
      height: 50px !important;
      width: 67px;
      left: 11vh !important;
      top: 12vh !important;
    }
    &-logo-footer{
      height: 72px !important;
      width: 82px;
      margin-right: 50px;
      margin-top: 20px;
    }
    &-org {
      position: absolute;
      color: #8D5A01;
      width: 27vh;
      text-align: center; 
      &.org-name {
        top: 17vh !important;
      }
      &.org-report {
        top: 19.2vh !important;
      }
    }
  }
  &-header {
    top: 17px;
    height: 12vh;
    &-asessment-name {
      padding-top: 3px;
    }
    &-seperator {
      color: #F7921E ;
      padding-left: 8px ;
      padding-right: 8px ;
    }
    &-score {
      top: 10px ;
      right: 9px ;
      &-overall-score {
       line-height: 1.8 !important; 
      }
    }
  }
  &-footer {
    position: relative;
    top: 6px;
  }
  &-question-content {
    &-p-index{
      vertical-align: top !important;
      &.question{
        padding-top: 2px;
      }
    }
    &-passage{
      line-height: 25px !important;
      position: relative;
    }
    &-border {
      border-bottom: 2px dashed #6A6969;
    }
  }
  &-question-option{
    position: absolute;
    left: -39px !important;
    top: 1px;
  }
}