@import "./styles/print.less";

@brand: #ed8b00; //ZS branding
@lightbrand: lighten(@brand, 10%);
@brand-secondary:#53565A; //ZS branding
@positive: @brand;
@negative: @brand-secondary;
@verylightgrey:lighten(grey, 45%);
@black: #4A4A4A;

.ui.inverted.segment{
  background: @brand-secondary !important;
}
.ui.brand.button {
  background-color: @brand;
  color: #ffff;
}
.ui.tv-secondary.brand.basic {
  border-color: @brand-secondary;
}
// Margin classes

.margin {
  &-no{
    margin-left: none !important;
    margin-right: none !important;
    margin-bottom: none !important;
    margin-top: none !important;
    margin: 0 !important;
  }
  &-five {
    margin: 5px;
  }
  &-ten {
    margin: 10px;
  }
  &-top {
    &-no {
      margin-top: 0px;
    }
    &-five {
      margin-top: 5px;
    }
    &-ten {
      margin-top: 10px;
    }
    &-fifteen {
      margin-top: 15px;
    }
    &-twenty {
      margin-top: 20px;
    }
    &-twenty-five {
      margin-top: 25px;
    }
    &-thirty {
      margin-top: 30px;
    }
    &-sixty {
      margin-top: 60px;
    }
    &-hundred {
      margin-top: 100px;
    }
  }
  &-left {
    &-no {
      margin-left: 0px;
    }
    &-five {
      margin-left: 5px;
    }
    &-ten {
      margin-left: 10px;
    }
    &-fifteen {
      margin-left: 15px;
    }
  }
  &-bottom {
    &-no {
      margin-bottom: 0px !important;
    }
    &-five{
      margin-bottom: 5px;
    }
    &-ten {
      margin-bottom: 10px;
    }
    &-fifteen {
      margin-bottom: 15px;
    }
  }
  &-right {
    &-no {
      margin-right: 0px;
    }
    &-five {
      margin-right: 5px;
    }
    &-ten {
      margin-right: 10px;
    }
  }
}

// Padding classes

.padding {
  &-no {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;

      &-top{
        padding-top:0px !important;
      }
      &-bottom{
        padding-bottom:0px !important;
      }
      &-left{
        padding-left:0px;
      }
      &-right{
        padding-right:0px;
      }
  }
  &-bg {
    padding: 30px;
    &-left{
      padding-left: 30px;
    }
    &-right{
      padding-right: 30px;
    }
    &-top{
      padding-top: 30px;
    }
    &-bottom{
      padding-bottom: 30px;
    }
  }

  &-md {
    padding: 15px;
    &-left{
      padding-left: 15px;
    }
    &-right{
      padding-right: 15px !important;
    }
    &-top{
      padding-top: 15px;
    }
    &-bottom{
      padding-bottom: 15px;
    }
  }

  &-sm {
    padding: 10px !important;
    &-left{
      padding-left: 10px !important;
    }
    &-right{
      padding-right: 10px !important;
    }
    &-top {
      padding-top: 10px !important;
    }
    &-bottom {
      padding-bottom: 10px !important;
    }
  }

  &-vs {
    padding: 5px !important;
    &-left{
      padding-left: 5px;
    }
    &-right{
      padding-right: 5px;
    }
    &-top{
      padding-top: 5px;
    }
    &-bottom{
      padding-bottom: 5px;
    }
  }
  
  &-sides{
    padding-left: 30px;
    padding-right: 30px;
  }
}

.text {
  &-hacker {
    font-family: Roboto;
    color: grey;
  }
  &-justify {
    text-align: justify;
  }
  &-right {
    text-align: right;
  }
  &-color{
    &-positive {
      color: @brand!important;
    }
    &-lightpositive{
      color: @lightbrand!important;
    }
    &-negative {
      color: @brand-secondary!important;
    }
    &-white {
      color: white !important;
    }
    &-black {
      color: darken(grey, 10%)!important;
    }
    &-lightblack {
      color: #4A4A4A !important
    }
    &-grey {
       color:#424242!important;
    }
    &-blue {
      color: #4A90E2!important;
    }
    &-lightgrey {
      color:lighten(grey, 10%)!important;
    }
    &-darkgrey {
      color: #6A6969 !important;
    }
    &-verylightgrey {
      color: @verylightgrey !important;
    }
    &-green {
      color: #66C4B6!important;
    }
    &-red {
      color: #F16657!important;
    }
    &-yellow {
      color: #FCCF68!important;
    }
  }
  &-weight {
    &-bold {
    font-weight: 700!important;
    }
    &-medium {
    font-weight: 500 !important;
    }
    &-normal {
      font-weight: 400!important;
    }
    &-light {
      font-weight: 300!important;
    }
    &-medium-bold {
      font-weight: 600!important;
    }
  }
  &-size{
    &-normal {
      font-size: 14px !important;
    }
    &-sixteen {
      font-size: 16px !important;
    }
    &-twelve {
      font-size: 12px !important;
    }
    &-thirteen {
      font-size: 13px !important;
    }
    &-fourteen {
      font-size: 14px !important;
    }
    &-ten {
      font-size: 10px !important;
    }
    &-small {
      font-size: 0.8em !important;
    }
    &-average {
      font-size: 0.9em !important;
    }
    &-normal {
    font-size: 1.1em !important;
    }
    &-medium {
    font-size: 1.2em !important;
    }
    &-large {
    font-size: 1.5em !important;
    }
    &-big {
    font-size: 2.5em !important;
    }
    &-bigger {
      font-size: 3.5em !important;
    }
  }
  &-decoration{
    &-no {
      text-decoration: none;
      text-decoration-line: none;
      text-decoration-style: none;
      text-decoration-color: none;
    }
  }
  &-center {
    text-align: center!important;
  }
  &-left {
    text-align: left!important;
  }
  &-right {
    text-align: right!important;
  }
  &-justify {
    text-align: justify!important;
  }
  &-uppercase {
    text-transform: uppercase !important;
  }
  &-titlecase {
    text-transform: capitalize !important;
  }
  &-align {
    &-center {
      text-align: center;
    }
  }
}

.item.logo#logo-banner {
  background-image: url(./images/ZoomRx_logo.png) !important; //ZS branding
  background-size: 50%;
  position: fixed;
  min-width: 155px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(255, 255, 255,1);
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  box-shadow: 2px 2px 5px 1px rgba(0,0,0,0.2);
  padding: 40px 15px;
}

body {
  background-image: url('./images/linedrawing-instructions-grey-83aef283ad4922ab8125603f21fe2942.jpg'); //Talview Branding
  font-family: Roboto;
}
.overflow-auto {
  overflow-y: auto;
}
.float-right{
  float: right;
}

.rectangle-score {
  height: 24px;
  width: 130px;
  position: absolute;
  left: 213px;
  border-radius: 8px;
  margin-top: 67px;
  text-align: center;
  background-color: #F7921E;
}

.circle-score {
  height: 75px;
  position: absolute;
  left: 230px;
  width: 97px;
  border: 5px solid #F7921E ;
  font-size: 49.76px;
  font-weight: 500;
  border-radius: 50px;
  padding: 0px;
  float: right;
  text-align: center;
  border-top-left-radius: 110px;  
  border-top-right-radius: 110px;
  border-bottom: 0;
}

.ui.error.message{
  border: 1px solid #D7554E !important;
  background-color: rgba(255,217,215,0.6) !important;
  color: #D7554E !important;
  padding: 7px 0px 10px 11px;
  height: 36px;
  border-radius: 0px;
}
.height {
  &-view-port {
    height: 100vh;
  }
  &-full {
    height: 100%;
  }
}

.width {
  &-view-port {
    width: 100vh;
  }
  &-full {
    width: 100%;
  }
}

.passage-height {
  overflow-y: auto;
  height: calc(100vh - 224px);
}

.question-height {
  height: calc(100vh - 299px);
  overflow: auto;
}
.ui.button{
  min-width: 100px;
}
.box-shadow-none {
  box-shadow: none !important;
}

.border {
  &-none {
    border: 0 !important;
  }
}

.bg {
  &-transparent {
    background-color: transparent !important
  }
  &-black {
    background-color: black !important
  }
  &-dark {
    background-color: #333742 !important;
  }

}

.error {
  &-image {
    height: 90px;
    position: relative;
    top: 4px;
  }
  &-container {
    min-width: 40vw;
    opacity: 0.9
  }
}
.close-icon {
  height: 8px;
  width: 8px; 
  color: #D7554E;
  opacity: .7;
  -webkit-transition: opacity .1s ease;
  transition: opacity .1s ease;
}

.cursor-pointer {
  cursor: pointer;
}

.ui.error.message {
  border: 0.3px solid #D7554E !important;
  background-color: rgba(255,217,215,0.6) !important;
  color: #D7554E !important;
  height: 36px;
  border-radius: 0px;
  padding: 8px
}
.floating-error {
  position: absolute !important;
  width: 100%;
  bottom: 0px;
  left: 0px;
}

.highcharts-axis-labels {
  font-weight: 500;
  font-size: 22px !important;
  font-weight: bold !important
}

.skill {
  &-header {
    height: 36px;
    padding-left: 48px;
    padding-bottom: 0px;
    line-height: 0;
    color: @black;
    &-name {
      width: 25%;
    }
    &-score {
      width: 80px;
      padding-right: 20px;
    }
  }
  &-graph {
    &-td {
      height: auto;
      div {
        position: absolute;
        top: 2px;
        width: 458px;
        height: auto;
      }
    }
    &-container {
      width: 90%;
      margin-left: 20px;
      top: 31px !important;
      position: relative;
    }
  }
  &-label { 
    color: @black;
    &.name {
      width: 25%;
      padding: 6px 0 6px 48px;
    }
    &.data {
      padding: 6px 0 6px 20px;
    }
  }
}

.report {
  &-loading {
    top: 45vh !important;
  }
}

.animated-background {
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeHolderShimmer;
  background: @brand;
  background: linear-gradient(to right, @brand 8%, darken(@brand, 50%) 18%, @brand 33%);
  background-size: 700px 104px;
  height:6px;
  border-radius:5px
}

@keyframes placeHolderShimmer {
  0%{ background-position: -200px 0; }
  50%{ background-position: 200px 0; }
  100%{ background-position: -200px 0; }
}

.ui.segments.border-none.box-shadow-none.question-image img{
  width: 100%;
}

.report-image img{
  max-width: 650px;
}
